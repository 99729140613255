import styled from 'styled-components';


export const CaixaInformacoes = styled.div`
    min-width: 50%;
  
 
`
export const CaixaInput = styled.div`
    display: flex;
    flex-direction: column;
    height: auto;
    align-items: center;

    & > .button1 {
        margin: auto 0px 0px auto;
    }


`